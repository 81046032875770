import _canvas3 from "./canvas.js";
import _svg3 from "./svg.js";
import _object3 from "./object.js";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _canvas = _canvas3;
var _canvas2 = _interopRequireDefault(_canvas);
var _svg = _svg3;
var _svg2 = _interopRequireDefault(_svg);
var _object = _object3;
var _object2 = _interopRequireDefault(_object);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
exports.default = {
  CanvasRenderer: _canvas2.default,
  SVGRenderer: _svg2.default,
  ObjectRenderer: _object2.default
};
export default exports;