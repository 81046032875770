import _CODE128_AUTO3 from "./CODE128_AUTO.js";
import _CODE128A3 from "./CODE128A.js";
import _CODE128B3 from "./CODE128B.js";
import _CODE128C3 from "./CODE128C.js";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CODE128C = exports.CODE128B = exports.CODE128A = exports.CODE128 = undefined;
var _CODE128_AUTO = _CODE128_AUTO3;
var _CODE128_AUTO2 = _interopRequireDefault(_CODE128_AUTO);
var _CODE128A = _CODE128A3;
var _CODE128A2 = _interopRequireDefault(_CODE128A);
var _CODE128B = _CODE128B3;
var _CODE128B2 = _interopRequireDefault(_CODE128B);
var _CODE128C = _CODE128C3;
var _CODE128C2 = _interopRequireDefault(_CODE128C);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
exports.CODE128 = _CODE128_AUTO2.default;
exports.CODE128A = _CODE128A2.default;
exports.CODE128B = _CODE128B2.default;
exports.CODE128C = _CODE128C2.default;
export default exports;