import _getOptionsFromElement3 from "./getOptionsFromElement.js";
import _renderers3 from "../renderers";
import _exceptions2 from "../exceptions/exceptions.js";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
  return typeof obj;
} : function (obj) {
  return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
}; /* global HTMLImageElement */
/* global HTMLCanvasElement */
/* global SVGElement */

var _getOptionsFromElement = _getOptionsFromElement3;
var _getOptionsFromElement2 = _interopRequireDefault(_getOptionsFromElement);
var _renderers = _renderers3;
var _renderers2 = _interopRequireDefault(_renderers);
var _exceptions = _exceptions2;
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

// Takes an element and returns an object with information about how
// it should be rendered
// This could also return an array with these objects
// {
//   element: The element that the renderer should draw on
//   renderer: The name of the renderer
//   afterRender (optional): If something has to done after the renderer
//     completed, calls afterRender (function)
//   options (optional): Options that can be defined in the element
// }

function getRenderProperties(element) {
  // If the element is a string, query select call again
  if (typeof element === "string") {
    return querySelectedRenderProperties(element);
  }
  // If element is array. Recursivly call with every object in the array
  else if (Array.isArray(element)) {
    var returnArray = [];
    for (var i = 0; i < element.length; i++) {
      returnArray.push(getRenderProperties(element[i]));
    }
    return returnArray;
  }
  // If element, render on canvas and set the uri as src
  else if (typeof HTMLCanvasElement !== "undefined" && element instanceof HTMLImageElement) {
    return newCanvasRenderProperties(element);
  }
  // If SVG
  else if (element && element.nodeName && element.nodeName.toLowerCase() === "svg" || typeof SVGElement !== "undefined" && element instanceof SVGElement) {
    return {
      element: element,
      options: (0, _getOptionsFromElement2.default)(element),
      renderer: _renderers2.default.SVGRenderer
    };
  }
  // If canvas (in browser)
  else if (typeof HTMLCanvasElement !== "undefined" && element instanceof HTMLCanvasElement) {
    return {
      element: element,
      options: (0, _getOptionsFromElement2.default)(element),
      renderer: _renderers2.default.CanvasRenderer
    };
  }
  // If canvas (in node)
  else if (element && element.getContext) {
    return {
      element: element,
      renderer: _renderers2.default.CanvasRenderer
    };
  } else if (element && (typeof element === "undefined" ? "undefined" : _typeof(element)) === "object" && !element.nodeName) {
    return {
      element: element,
      renderer: _renderers2.default.ObjectRenderer
    };
  } else {
    throw new _exceptions.InvalidElementException();
  }
}
function querySelectedRenderProperties(string) {
  var selector = document.querySelectorAll(string);
  if (selector.length === 0) {
    return undefined;
  } else {
    var returnArray = [];
    for (var i = 0; i < selector.length; i++) {
      returnArray.push(getRenderProperties(selector[i]));
    }
    return returnArray;
  }
}
function newCanvasRenderProperties(imgElement) {
  var canvas = document.createElement("canvas");
  return {
    element: canvas,
    options: (0, _getOptionsFromElement2.default)(imgElement),
    renderer: _renderers2.default.CanvasRenderer,
    afterRender: function afterRender() {
      imgElement.setAttribute("src", canvas.toDataURL());
    }
  };
}
exports.default = getRenderProperties;
export default exports;