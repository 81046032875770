var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();
function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

/*eslint no-console: 0 */

var ErrorHandler = function () {
  function ErrorHandler(api) {
    _classCallCheck(this, ErrorHandler);
    this.api = api;
  }
  _createClass(ErrorHandler, [{
    key: "handleCatch",
    value: function handleCatch(e) {
      // If babel supported extending of Error in a correct way instanceof would be used here
      if (e.name === "InvalidInputException") {
        if (this.api._options.valid !== this.api._defaults.valid) {
          this.api._options.valid(false);
        } else {
          throw e.message;
        }
      } else {
        throw e;
      }
      this.api.render = function () {};
    }
  }, {
    key: "wrapBarcodeCall",
    value: function wrapBarcodeCall(func) {
      try {
        var result = func.apply(undefined, arguments);
        this.api._options.valid(true);
        return result;
      } catch (e) {
        this.handleCatch(e);
        return this.api;
      }
    }
  }]);
  return ErrorHandler;
}();
exports.default = ErrorHandler;
export default exports;