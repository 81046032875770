import _CODE3 from "./CODE39/";
import _CODE4 from "./CODE128/";
import _EAN_UPC2 from "./EAN_UPC/";
import _ITF2 from "./ITF/";
import _MSI2 from "./MSI/";
import _pharmacode2 from "./pharmacode/";
import _codabar2 from "./codabar";
import _GenericBarcode2 from "./GenericBarcode/";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _CODE = _CODE3;
var _CODE2 = _CODE4;
var _EAN_UPC = _EAN_UPC2;
var _ITF = _ITF2;
var _MSI = _MSI2;
var _pharmacode = _pharmacode2;
var _codabar = _codabar2;
var _GenericBarcode = _GenericBarcode2;
exports.default = {
  CODE39: _CODE.CODE39,
  CODE128: _CODE2.CODE128,
  CODE128A: _CODE2.CODE128A,
  CODE128B: _CODE2.CODE128B,
  CODE128C: _CODE2.CODE128C,
  EAN13: _EAN_UPC.EAN13,
  EAN8: _EAN_UPC.EAN8,
  EAN5: _EAN_UPC.EAN5,
  EAN2: _EAN_UPC.EAN2,
  UPC: _EAN_UPC.UPC,
  UPCE: _EAN_UPC.UPCE,
  ITF14: _ITF.ITF14,
  ITF: _ITF.ITF,
  MSI: _MSI.MSI,
  MSI10: _MSI.MSI10,
  MSI11: _MSI.MSI11,
  MSI1010: _MSI.MSI1010,
  MSI1110: _MSI.MSI1110,
  pharmacode: _pharmacode.pharmacode,
  codabar: _codabar.codabar,
  GenericBarcode: _GenericBarcode.GenericBarcode
};
export default exports;