import _EAN9 from "./EAN13.js";
import _EAN10 from "./EAN8.js";
import _EAN11 from "./EAN5.js";
import _EAN12 from "./EAN2.js";
import _UPC3 from "./UPC.js";
import _UPCE3 from "./UPCE.js";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPCE = exports.UPC = exports.EAN2 = exports.EAN5 = exports.EAN8 = exports.EAN13 = undefined;
var _EAN = _EAN9;
var _EAN2 = _interopRequireDefault(_EAN);
var _EAN3 = _EAN10;
var _EAN4 = _interopRequireDefault(_EAN3);
var _EAN5 = _EAN11;
var _EAN6 = _interopRequireDefault(_EAN5);
var _EAN7 = _EAN12;
var _EAN8 = _interopRequireDefault(_EAN7);
var _UPC = _UPC3;
var _UPC2 = _interopRequireDefault(_UPC);
var _UPCE = _UPCE3;
var _UPCE2 = _interopRequireDefault(_UPCE);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
exports.EAN13 = _EAN2.default;
exports.EAN8 = _EAN4.default;
exports.EAN5 = _EAN6.default;
exports.EAN2 = _EAN8.default;
exports.UPC = _UPC2.default;
exports.UPCE = _UPCE2.default;
export default exports;