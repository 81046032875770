import _Barcode4 from "../Barcode.js";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CODE39 = undefined;
var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();
var _Barcode2 = _Barcode4;
var _Barcode3 = _interopRequireDefault(_Barcode2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}
function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }
  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}
function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
} // Encoding documentation:
// https://en.wikipedia.org/wiki/Code_39#Encoding

var CODE39 = function (_Barcode) {
  _inherits(CODE39, _Barcode);
  function CODE39(data, options) {
    _classCallCheck(this, CODE39);
    data = data.toUpperCase();

    // Calculate mod43 checksum if enabled
    if (options.mod43) {
      data += getCharacter(mod43checksum(data));
    }
    return _possibleConstructorReturn(this, (CODE39.__proto__ || Object.getPrototypeOf(CODE39)).call(this, data, options));
  }
  _createClass(CODE39, [{
    key: "encode",
    value: function encode() {
      // First character is always a *
      var result = getEncoding("*");

      // Take every character and add the binary representation to the result
      for (var i = 0; i < this.data.length; i++) {
        result += getEncoding(this.data[i]) + "0";
      }

      // Last character is always a *
      result += getEncoding("*");
      return {
        data: result,
        text: this.text
      };
    }
  }, {
    key: "valid",
    value: function valid() {
      return this.data.search(/^[0-9A-Z\-\.\ \$\/\+\%]+$/) !== -1;
    }
  }]);
  return CODE39;
}(_Barcode3.default);

// All characters. The position in the array is the (checksum) value

var characters = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "-", ".", " ", "$", "/", "+", "%", "*"];

// The decimal representation of the characters, is converted to the
// corresponding binary with the getEncoding function
var encodings = [20957, 29783, 23639, 30485, 20951, 29813, 23669, 20855, 29789, 23645, 29975, 23831, 30533, 22295, 30149, 24005, 21623, 29981, 23837, 22301, 30023, 23879, 30545, 22343, 30161, 24017, 21959, 30065, 23921, 22385, 29015, 18263, 29141, 17879, 29045, 18293, 17783, 29021, 18269, 17477, 17489, 17681, 20753, 35770];

// Get the binary representation of a character by converting the encodings
// from decimal to binary
function getEncoding(character) {
  return getBinary(characterValue(character));
}
function getBinary(characterValue) {
  return encodings[characterValue].toString(2);
}
function getCharacter(characterValue) {
  return characters[characterValue];
}
function characterValue(character) {
  return characters.indexOf(character);
}
function mod43checksum(data) {
  var checksum = 0;
  for (var i = 0; i < data.length; i++) {
    checksum += characterValue(data[i]);
  }
  checksum = checksum % 43;
  return checksum;
}
exports.CODE39 = CODE39;
export default exports;