var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mod10 = mod10;
exports.mod11 = mod11;
function mod10(number) {
  var sum = 0;
  for (var i = 0; i < number.length; i++) {
    var n = parseInt(number[i]);
    if ((i + number.length) % 2 === 0) {
      sum += n;
    } else {
      sum += n * 2 % 10 + Math.floor(n * 2 / 10);
    }
  }
  return (10 - sum % 10) % 10;
}
function mod11(number) {
  var sum = 0;
  var weights = [2, 3, 4, 5, 6, 7];
  for (var i = 0; i < number.length; i++) {
    var n = parseInt(number[number.length - 1 - i]);
    sum += weights[i % weights.length] * n;
  }
  return (11 - sum % 11) % 11;
}
export default exports;