import _optionsFromStrings3 from "./optionsFromStrings.js";
import _defaults3 from "../options/defaults.js";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _optionsFromStrings = _optionsFromStrings3;
var _optionsFromStrings2 = _interopRequireDefault(_optionsFromStrings);
var _defaults = _defaults3;
var _defaults2 = _interopRequireDefault(_defaults);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function getOptionsFromElement(element) {
  var options = {};
  for (var property in _defaults2.default) {
    if (_defaults2.default.hasOwnProperty(property)) {
      // jsbarcode-*
      if (element.hasAttribute("jsbarcode-" + property.toLowerCase())) {
        options[property] = element.getAttribute("jsbarcode-" + property.toLowerCase());
      }

      // data-*
      if (element.hasAttribute("data-" + property.toLowerCase())) {
        options[property] = element.getAttribute("data-" + property.toLowerCase());
      }
    }
  }
  options["value"] = element.getAttribute("jsbarcode-value") || element.getAttribute("data-value");

  // Since all atributes are string they need to be converted to integers
  options = (0, _optionsFromStrings2.default)(options);
  return options;
}
exports.default = getOptionsFromElement;
export default exports;